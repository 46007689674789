@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap');

:root {
  --assa-blue: #28A0CF;
  
}

@layer base {


  html {
    scroll-behavior: smooth;
    background-color: var(--assa-blue);
  }

  html, body {
      font-family: "Open Sans", sans-serif;
  }
  .app {
    width: 100vw;
    height: 100vh;
  }

  h1 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 1em;
  }

}